'use strict'

module.exports = {
  map: require('./map'),
  asyncMap: require('./async-map'),
  filter: require('./filter'),
  filterNot: require('./filter-not'),
  through: require('./through'),
  take: require('./take'),
  unique: require('./unique'),
  nonUnique: require('./non-unique'),
  flatten: require('./flatten')
}



