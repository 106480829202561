module.exports = toBuffer

var makeBuffer = Buffer.from && Buffer.from !== Uint8Array.from ? Buffer.from : bufferFrom

function bufferFrom (buf, enc) {
  return new Buffer(buf, enc)
}

function toBuffer (buf, enc) {
  if (Buffer.isBuffer(buf)) return buf
  if (typeof buf === 'string') return makeBuffer(buf, enc)
  if (Array.isArray(buf)) return makeBuffer(buf)
  throw new Error('Input should be a buffer or a string')
}
