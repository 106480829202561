'use strict'

module.exports = {
  drain: require('./drain'),
  onEnd: require('./on-end'),
  log: require('./log'),
  find: require('./find'),
  reduce: require('./reduce'),
  collect: require('./collect'),
  concat: require('./concat')
}

