'use strict'

// spec and table at: https://github.com/multiformats/multicodec

exports = module.exports

// Miscellaneous
exports['raw'] = Buffer.from('55', 'hex')

// bases encodings
exports['base1'] = Buffer.from('01', 'hex')
exports['base2'] = Buffer.from('00', 'hex')
exports['base8'] = Buffer.from('07', 'hex')
exports['base10'] = Buffer.from('09', 'hex')

// Serialization formats
exports['cbor'] = Buffer.from('51', 'hex')
exports['protobuf'] = Buffer.from('50', 'hex')
exports['rlp'] = Buffer.from('60', 'hex')
exports['bencode'] = Buffer.from('63', 'hex')

// Multiformats
exports['multicodec'] = Buffer.from('30', 'hex')
exports['multihash'] = Buffer.from('31', 'hex')
exports['multiaddr'] = Buffer.from('32', 'hex')
exports['multibase'] = Buffer.from('33', 'hex')
exports['md4'] = Buffer.from('d4', 'hex')
exports['md5'] = Buffer.from('d5', 'hex')

// multihashes
exports['sha1'] = Buffer.from('11', 'hex')
exports['sha2-256'] = Buffer.from('12', 'hex')
exports['sha2-512'] = Buffer.from('13', 'hex')
exports['dbl-sha2-256'] = Buffer.from('56', 'hex')
exports['sha3-224'] = Buffer.from('17', 'hex')
exports['sha3-256'] = Buffer.from('16', 'hex')
exports['sha3-384'] = Buffer.from('15', 'hex')
exports['sha3-512'] = Buffer.from('14', 'hex')
exports['shake-128'] = Buffer.from('18', 'hex')
exports['shake-256'] = Buffer.from('19', 'hex')
exports['keccak-224'] = Buffer.from('1a', 'hex')
exports['keccak-256'] = Buffer.from('1b', 'hex')
exports['keccak-384'] = Buffer.from('1c', 'hex')
exports['keccak-512'] = Buffer.from('1d', 'hex')
exports['murmur3'] = Buffer.from('22', 'hex')
exports['blake2b-8'] = Buffer.from('b201', 'hex')
exports['blake2b-16'] = Buffer.from('b202', 'hex')
exports['blake2b-24'] = Buffer.from('b203', 'hex')
exports['blake2b-32'] = Buffer.from('b204', 'hex')
exports['blake2b-40'] = Buffer.from('b205', 'hex')
exports['blake2b-48'] = Buffer.from('b206', 'hex')
exports['blake2b-56'] = Buffer.from('b207', 'hex')
exports['blake2b-64'] = Buffer.from('b208', 'hex')
exports['blake2b-72'] = Buffer.from('b209', 'hex')
exports['blake2b-80'] = Buffer.from('b20a', 'hex')
exports['blake2b-88'] = Buffer.from('b20b', 'hex')
exports['blake2b-96'] = Buffer.from('b20c', 'hex')
exports['blake2b-104'] = Buffer.from('b20d', 'hex')
exports['blake2b-112'] = Buffer.from('b20e', 'hex')
exports['blake2b-120'] = Buffer.from('b20f', 'hex')
exports['blake2b-128'] = Buffer.from('b210', 'hex')
exports['blake2b-136'] = Buffer.from('b211', 'hex')
exports['blake2b-144'] = Buffer.from('b212', 'hex')
exports['blake2b-152'] = Buffer.from('b213', 'hex')
exports['blake2b-160'] = Buffer.from('b214', 'hex')
exports['blake2b-168'] = Buffer.from('b215', 'hex')
exports['blake2b-176'] = Buffer.from('b216', 'hex')
exports['blake2b-184'] = Buffer.from('b217', 'hex')
exports['blake2b-192'] = Buffer.from('b218', 'hex')
exports['blake2b-200'] = Buffer.from('b219', 'hex')
exports['blake2b-208'] = Buffer.from('b21a', 'hex')
exports['blake2b-216'] = Buffer.from('b21b', 'hex')
exports['blake2b-224'] = Buffer.from('b21c', 'hex')
exports['blake2b-232'] = Buffer.from('b21d', 'hex')
exports['blake2b-240'] = Buffer.from('b21e', 'hex')
exports['blake2b-248'] = Buffer.from('b21f', 'hex')
exports['blake2b-256'] = Buffer.from('b220', 'hex')
exports['blake2b-264'] = Buffer.from('b221', 'hex')
exports['blake2b-272'] = Buffer.from('b222', 'hex')
exports['blake2b-280'] = Buffer.from('b223', 'hex')
exports['blake2b-288'] = Buffer.from('b224', 'hex')
exports['blake2b-296'] = Buffer.from('b225', 'hex')
exports['blake2b-304'] = Buffer.from('b226', 'hex')
exports['blake2b-312'] = Buffer.from('b227', 'hex')
exports['blake2b-320'] = Buffer.from('b228', 'hex')
exports['blake2b-328'] = Buffer.from('b229', 'hex')
exports['blake2b-336'] = Buffer.from('b22a', 'hex')
exports['blake2b-344'] = Buffer.from('b22b', 'hex')
exports['blake2b-352'] = Buffer.from('b22c', 'hex')
exports['blake2b-360'] = Buffer.from('b22d', 'hex')
exports['blake2b-368'] = Buffer.from('b22e', 'hex')
exports['blake2b-376'] = Buffer.from('b22f', 'hex')
exports['blake2b-384'] = Buffer.from('b230', 'hex')
exports['blake2b-392'] = Buffer.from('b231', 'hex')
exports['blake2b-400'] = Buffer.from('b232', 'hex')
exports['blake2b-408'] = Buffer.from('b233', 'hex')
exports['blake2b-416'] = Buffer.from('b234', 'hex')
exports['blake2b-424'] = Buffer.from('b235', 'hex')
exports['blake2b-432'] = Buffer.from('b236', 'hex')
exports['blake2b-440'] = Buffer.from('b237', 'hex')
exports['blake2b-448'] = Buffer.from('b238', 'hex')
exports['blake2b-456'] = Buffer.from('b239', 'hex')
exports['blake2b-464'] = Buffer.from('b23a', 'hex')
exports['blake2b-472'] = Buffer.from('b23b', 'hex')
exports['blake2b-480'] = Buffer.from('b23c', 'hex')
exports['blake2b-488'] = Buffer.from('b23d', 'hex')
exports['blake2b-496'] = Buffer.from('b23e', 'hex')
exports['blake2b-504'] = Buffer.from('b23f', 'hex')
exports['blake2b-512'] = Buffer.from('b240', 'hex')
exports['blake2s-8'] = Buffer.from('b241', 'hex')
exports['blake2s-16'] = Buffer.from('b242', 'hex')
exports['blake2s-24'] = Buffer.from('b243', 'hex')
exports['blake2s-32'] = Buffer.from('b244', 'hex')
exports['blake2s-40'] = Buffer.from('b245', 'hex')
exports['blake2s-48'] = Buffer.from('b246', 'hex')
exports['blake2s-56'] = Buffer.from('b247', 'hex')
exports['blake2s-64'] = Buffer.from('b248', 'hex')
exports['blake2s-72'] = Buffer.from('b249', 'hex')
exports['blake2s-80'] = Buffer.from('b24a', 'hex')
exports['blake2s-88'] = Buffer.from('b24b', 'hex')
exports['blake2s-96'] = Buffer.from('b24c', 'hex')
exports['blake2s-104'] = Buffer.from('b24d', 'hex')
exports['blake2s-112'] = Buffer.from('b24e', 'hex')
exports['blake2s-120'] = Buffer.from('b24f', 'hex')
exports['blake2s-128'] = Buffer.from('b250', 'hex')
exports['blake2s-136'] = Buffer.from('b251', 'hex')
exports['blake2s-144'] = Buffer.from('b252', 'hex')
exports['blake2s-152'] = Buffer.from('b253', 'hex')
exports['blake2s-160'] = Buffer.from('b254', 'hex')
exports['blake2s-168'] = Buffer.from('b255', 'hex')
exports['blake2s-176'] = Buffer.from('b256', 'hex')
exports['blake2s-184'] = Buffer.from('b257', 'hex')
exports['blake2s-192'] = Buffer.from('b258', 'hex')
exports['blake2s-200'] = Buffer.from('b259', 'hex')
exports['blake2s-208'] = Buffer.from('b25a', 'hex')
exports['blake2s-216'] = Buffer.from('b25b', 'hex')
exports['blake2s-224'] = Buffer.from('b25c', 'hex')
exports['blake2s-232'] = Buffer.from('b25d', 'hex')
exports['blake2s-240'] = Buffer.from('b25e', 'hex')
exports['blake2s-248'] = Buffer.from('b25f', 'hex')
exports['blake2s-256'] = Buffer.from('b260', 'hex')
exports['skein256-8'] = Buffer.from('b301', 'hex')
exports['skein256-16'] = Buffer.from('b302', 'hex')
exports['skein256-24'] = Buffer.from('b303', 'hex')
exports['skein256-32'] = Buffer.from('b304', 'hex')
exports['skein256-40'] = Buffer.from('b305', 'hex')
exports['skein256-48'] = Buffer.from('b306', 'hex')
exports['skein256-56'] = Buffer.from('b307', 'hex')
exports['skein256-64'] = Buffer.from('b308', 'hex')
exports['skein256-72'] = Buffer.from('b309', 'hex')
exports['skein256-80'] = Buffer.from('b30a', 'hex')
exports['skein256-88'] = Buffer.from('b30b', 'hex')
exports['skein256-96'] = Buffer.from('b30c', 'hex')
exports['skein256-104'] = Buffer.from('b30d', 'hex')
exports['skein256-112'] = Buffer.from('b30e', 'hex')
exports['skein256-120'] = Buffer.from('b30f', 'hex')
exports['skein256-128'] = Buffer.from('b310', 'hex')
exports['skein256-136'] = Buffer.from('b311', 'hex')
exports['skein256-144'] = Buffer.from('b312', 'hex')
exports['skein256-152'] = Buffer.from('b313', 'hex')
exports['skein256-160'] = Buffer.from('b314', 'hex')
exports['skein256-168'] = Buffer.from('b315', 'hex')
exports['skein256-176'] = Buffer.from('b316', 'hex')
exports['skein256-184'] = Buffer.from('b317', 'hex')
exports['skein256-192'] = Buffer.from('b318', 'hex')
exports['skein256-200'] = Buffer.from('b319', 'hex')
exports['skein256-208'] = Buffer.from('b31a', 'hex')
exports['skein256-216'] = Buffer.from('b31b', 'hex')
exports['skein256-224'] = Buffer.from('b31c', 'hex')
exports['skein256-232'] = Buffer.from('b31d', 'hex')
exports['skein256-240'] = Buffer.from('b31e', 'hex')
exports['skein256-248'] = Buffer.from('b31f', 'hex')
exports['skein256-256'] = Buffer.from('b320', 'hex')
exports['skein512-8'] = Buffer.from('b321', 'hex')
exports['skein512-16'] = Buffer.from('b322', 'hex')
exports['skein512-24'] = Buffer.from('b323', 'hex')
exports['skein512-32'] = Buffer.from('b324', 'hex')
exports['skein512-40'] = Buffer.from('b325', 'hex')
exports['skein512-48'] = Buffer.from('b326', 'hex')
exports['skein512-56'] = Buffer.from('b327', 'hex')
exports['skein512-64'] = Buffer.from('b328', 'hex')
exports['skein512-72'] = Buffer.from('b329', 'hex')
exports['skein512-80'] = Buffer.from('b32a', 'hex')
exports['skein512-88'] = Buffer.from('b32b', 'hex')
exports['skein512-96'] = Buffer.from('b32c', 'hex')
exports['skein512-104'] = Buffer.from('b32d', 'hex')
exports['skein512-112'] = Buffer.from('b32e', 'hex')
exports['skein512-120'] = Buffer.from('b32f', 'hex')
exports['skein512-128'] = Buffer.from('b330', 'hex')
exports['skein512-136'] = Buffer.from('b331', 'hex')
exports['skein512-144'] = Buffer.from('b332', 'hex')
exports['skein512-152'] = Buffer.from('b333', 'hex')
exports['skein512-160'] = Buffer.from('b334', 'hex')
exports['skein512-168'] = Buffer.from('b335', 'hex')
exports['skein512-176'] = Buffer.from('b336', 'hex')
exports['skein512-184'] = Buffer.from('b337', 'hex')
exports['skein512-192'] = Buffer.from('b338', 'hex')
exports['skein512-200'] = Buffer.from('b339', 'hex')
exports['skein512-208'] = Buffer.from('b33a', 'hex')
exports['skein512-216'] = Buffer.from('b33b', 'hex')
exports['skein512-224'] = Buffer.from('b33c', 'hex')
exports['skein512-232'] = Buffer.from('b33d', 'hex')
exports['skein512-240'] = Buffer.from('b33e', 'hex')
exports['skein512-248'] = Buffer.from('b33f', 'hex')
exports['skein512-256'] = Buffer.from('b340', 'hex')
exports['skein512-264'] = Buffer.from('b341', 'hex')
exports['skein512-272'] = Buffer.from('b342', 'hex')
exports['skein512-280'] = Buffer.from('b343', 'hex')
exports['skein512-288'] = Buffer.from('b344', 'hex')
exports['skein512-296'] = Buffer.from('b345', 'hex')
exports['skein512-304'] = Buffer.from('b346', 'hex')
exports['skein512-312'] = Buffer.from('b347', 'hex')
exports['skein512-320'] = Buffer.from('b348', 'hex')
exports['skein512-328'] = Buffer.from('b349', 'hex')
exports['skein512-336'] = Buffer.from('b34a', 'hex')
exports['skein512-344'] = Buffer.from('b34b', 'hex')
exports['skein512-352'] = Buffer.from('b34c', 'hex')
exports['skein512-360'] = Buffer.from('b34d', 'hex')
exports['skein512-368'] = Buffer.from('b34e', 'hex')
exports['skein512-376'] = Buffer.from('b34f', 'hex')
exports['skein512-384'] = Buffer.from('b350', 'hex')
exports['skein512-392'] = Buffer.from('b351', 'hex')
exports['skein512-400'] = Buffer.from('b352', 'hex')
exports['skein512-408'] = Buffer.from('b353', 'hex')
exports['skein512-416'] = Buffer.from('b354', 'hex')
exports['skein512-424'] = Buffer.from('b355', 'hex')
exports['skein512-432'] = Buffer.from('b356', 'hex')
exports['skein512-440'] = Buffer.from('b357', 'hex')
exports['skein512-448'] = Buffer.from('b358', 'hex')
exports['skein512-456'] = Buffer.from('b359', 'hex')
exports['skein512-464'] = Buffer.from('b35a', 'hex')
exports['skein512-472'] = Buffer.from('b35b', 'hex')
exports['skein512-480'] = Buffer.from('b35c', 'hex')
exports['skein512-488'] = Buffer.from('b35d', 'hex')
exports['skein512-496'] = Buffer.from('b35e', 'hex')
exports['skein512-504'] = Buffer.from('b35f', 'hex')
exports['skein512-512'] = Buffer.from('b360', 'hex')
exports['skein1024-8'] = Buffer.from('b361', 'hex')
exports['skein1024-16'] = Buffer.from('b362', 'hex')
exports['skein1024-24'] = Buffer.from('b363', 'hex')
exports['skein1024-32'] = Buffer.from('b364', 'hex')
exports['skein1024-40'] = Buffer.from('b365', 'hex')
exports['skein1024-48'] = Buffer.from('b366', 'hex')
exports['skein1024-56'] = Buffer.from('b367', 'hex')
exports['skein1024-64'] = Buffer.from('b368', 'hex')
exports['skein1024-72'] = Buffer.from('b369', 'hex')
exports['skein1024-80'] = Buffer.from('b36a', 'hex')
exports['skein1024-88'] = Buffer.from('b36b', 'hex')
exports['skein1024-96'] = Buffer.from('b36c', 'hex')
exports['skein1024-104'] = Buffer.from('b36d', 'hex')
exports['skein1024-112'] = Buffer.from('b36e', 'hex')
exports['skein1024-120'] = Buffer.from('b36f', 'hex')
exports['skein1024-128'] = Buffer.from('b370', 'hex')
exports['skein1024-136'] = Buffer.from('b371', 'hex')
exports['skein1024-144'] = Buffer.from('b372', 'hex')
exports['skein1024-152'] = Buffer.from('b373', 'hex')
exports['skein1024-160'] = Buffer.from('b374', 'hex')
exports['skein1024-168'] = Buffer.from('b375', 'hex')
exports['skein1024-176'] = Buffer.from('b376', 'hex')
exports['skein1024-184'] = Buffer.from('b377', 'hex')
exports['skein1024-192'] = Buffer.from('b378', 'hex')
exports['skein1024-200'] = Buffer.from('b379', 'hex')
exports['skein1024-208'] = Buffer.from('b37a', 'hex')
exports['skein1024-216'] = Buffer.from('b37b', 'hex')
exports['skein1024-224'] = Buffer.from('b37c', 'hex')
exports['skein1024-232'] = Buffer.from('b37d', 'hex')
exports['skein1024-240'] = Buffer.from('b37e', 'hex')
exports['skein1024-248'] = Buffer.from('b37f', 'hex')
exports['skein1024-256'] = Buffer.from('b380', 'hex')
exports['skein1024-264'] = Buffer.from('b381', 'hex')
exports['skein1024-272'] = Buffer.from('b382', 'hex')
exports['skein1024-280'] = Buffer.from('b383', 'hex')
exports['skein1024-288'] = Buffer.from('b384', 'hex')
exports['skein1024-296'] = Buffer.from('b385', 'hex')
exports['skein1024-304'] = Buffer.from('b386', 'hex')
exports['skein1024-312'] = Buffer.from('b387', 'hex')
exports['skein1024-320'] = Buffer.from('b388', 'hex')
exports['skein1024-328'] = Buffer.from('b389', 'hex')
exports['skein1024-336'] = Buffer.from('b38a', 'hex')
exports['skein1024-344'] = Buffer.from('b38b', 'hex')
exports['skein1024-352'] = Buffer.from('b38c', 'hex')
exports['skein1024-360'] = Buffer.from('b38d', 'hex')
exports['skein1024-368'] = Buffer.from('b38e', 'hex')
exports['skein1024-376'] = Buffer.from('b38f', 'hex')
exports['skein1024-384'] = Buffer.from('b390', 'hex')
exports['skein1024-392'] = Buffer.from('b391', 'hex')
exports['skein1024-400'] = Buffer.from('b392', 'hex')
exports['skein1024-408'] = Buffer.from('b393', 'hex')
exports['skein1024-416'] = Buffer.from('b394', 'hex')
exports['skein1024-424'] = Buffer.from('b395', 'hex')
exports['skein1024-432'] = Buffer.from('b396', 'hex')
exports['skein1024-440'] = Buffer.from('b397', 'hex')
exports['skein1024-448'] = Buffer.from('b398', 'hex')
exports['skein1024-456'] = Buffer.from('b399', 'hex')
exports['skein1024-464'] = Buffer.from('b39a', 'hex')
exports['skein1024-472'] = Buffer.from('b39b', 'hex')
exports['skein1024-480'] = Buffer.from('b39c', 'hex')
exports['skein1024-488'] = Buffer.from('b39d', 'hex')
exports['skein1024-496'] = Buffer.from('b39e', 'hex')
exports['skein1024-504'] = Buffer.from('b39f', 'hex')
exports['skein1024-512'] = Buffer.from('b3a0', 'hex')
exports['skein1024-520'] = Buffer.from('b3a1', 'hex')
exports['skein1024-528'] = Buffer.from('b3a2', 'hex')
exports['skein1024-536'] = Buffer.from('b3a3', 'hex')
exports['skein1024-544'] = Buffer.from('b3a4', 'hex')
exports['skein1024-552'] = Buffer.from('b3a5', 'hex')
exports['skein1024-560'] = Buffer.from('b3a6', 'hex')
exports['skein1024-568'] = Buffer.from('b3a7', 'hex')
exports['skein1024-576'] = Buffer.from('b3a8', 'hex')
exports['skein1024-584'] = Buffer.from('b3a9', 'hex')
exports['skein1024-592'] = Buffer.from('b3aa', 'hex')
exports['skein1024-600'] = Buffer.from('b3ab', 'hex')
exports['skein1024-608'] = Buffer.from('b3ac', 'hex')
exports['skein1024-616'] = Buffer.from('b3ad', 'hex')
exports['skein1024-624'] = Buffer.from('b3ae', 'hex')
exports['skein1024-632'] = Buffer.from('b3af', 'hex')
exports['skein1024-640'] = Buffer.from('b3b0', 'hex')
exports['skein1024-648'] = Buffer.from('b3b1', 'hex')
exports['skein1024-656'] = Buffer.from('b3b2', 'hex')
exports['skein1024-664'] = Buffer.from('b3b3', 'hex')
exports['skein1024-672'] = Buffer.from('b3b4', 'hex')
exports['skein1024-680'] = Buffer.from('b3b5', 'hex')
exports['skein1024-688'] = Buffer.from('b3b6', 'hex')
exports['skein1024-696'] = Buffer.from('b3b7', 'hex')
exports['skein1024-704'] = Buffer.from('b3b8', 'hex')
exports['skein1024-712'] = Buffer.from('b3b9', 'hex')
exports['skein1024-720'] = Buffer.from('b3ba', 'hex')
exports['skein1024-728'] = Buffer.from('b3bb', 'hex')
exports['skein1024-736'] = Buffer.from('b3bc', 'hex')
exports['skein1024-744'] = Buffer.from('b3bd', 'hex')
exports['skein1024-752'] = Buffer.from('b3be', 'hex')
exports['skein1024-760'] = Buffer.from('b3bf', 'hex')
exports['skein1024-768'] = Buffer.from('b3c0', 'hex')
exports['skein1024-776'] = Buffer.from('b3c1', 'hex')
exports['skein1024-784'] = Buffer.from('b3c2', 'hex')
exports['skein1024-792'] = Buffer.from('b3c3', 'hex')
exports['skein1024-800'] = Buffer.from('b3c4', 'hex')
exports['skein1024-808'] = Buffer.from('b3c5', 'hex')
exports['skein1024-816'] = Buffer.from('b3c6', 'hex')
exports['skein1024-824'] = Buffer.from('b3c7', 'hex')
exports['skein1024-832'] = Buffer.from('b3c8', 'hex')
exports['skein1024-840'] = Buffer.from('b3c9', 'hex')
exports['skein1024-848'] = Buffer.from('b3ca', 'hex')
exports['skein1024-856'] = Buffer.from('b3cb', 'hex')
exports['skein1024-864'] = Buffer.from('b3cc', 'hex')
exports['skein1024-872'] = Buffer.from('b3cd', 'hex')
exports['skein1024-880'] = Buffer.from('b3ce', 'hex')
exports['skein1024-888'] = Buffer.from('b3cf', 'hex')
exports['skein1024-896'] = Buffer.from('b3d0', 'hex')
exports['skein1024-904'] = Buffer.from('b3d1', 'hex')
exports['skein1024-912'] = Buffer.from('b3d2', 'hex')
exports['skein1024-920'] = Buffer.from('b3d3', 'hex')
exports['skein1024-928'] = Buffer.from('b3d4', 'hex')
exports['skein1024-936'] = Buffer.from('b3d5', 'hex')
exports['skein1024-944'] = Buffer.from('b3d6', 'hex')
exports['skein1024-952'] = Buffer.from('b3d7', 'hex')
exports['skein1024-960'] = Buffer.from('b3d8', 'hex')
exports['skein1024-968'] = Buffer.from('b3d9', 'hex')
exports['skein1024-976'] = Buffer.from('b3da', 'hex')
exports['skein1024-984'] = Buffer.from('b3db', 'hex')
exports['skein1024-992'] = Buffer.from('b3dc', 'hex')
exports['skein1024-1000'] = Buffer.from('b3dd', 'hex')
exports['skein1024-1008'] = Buffer.from('b3de', 'hex')
exports['skein1024-1016'] = Buffer.from('b3df', 'hex')
exports['skein1024-1024'] = Buffer.from('b3e0', 'hex')

// multiaddrs
exports['ip4'] = Buffer.from('04', 'hex')
exports['ip6'] = Buffer.from('29', 'hex')
exports['tcp'] = Buffer.from('06', 'hex')
exports['udp'] = Buffer.from('0111', 'hex')
exports['dccp'] = Buffer.from('21', 'hex')
exports['sctp'] = Buffer.from('84', 'hex')
exports['udt'] = Buffer.from('012d', 'hex')
exports['utp'] = Buffer.from('012e', 'hex')
exports['ipfs'] = Buffer.from('01a5', 'hex')
exports['http'] = Buffer.from('01e0', 'hex')
exports['https'] = Buffer.from('01bb', 'hex')
exports['quic'] = Buffer.from('01cc', 'hex')
exports['ws'] = Buffer.from('01dd', 'hex')
exports['onion'] = Buffer.from('01bc', 'hex')
exports['p2p-circuit'] = Buffer.from('0122', 'hex')

// archiving formats

// image formats

// video formats

// VCS formats
exports['git-raw'] = Buffer.from('78', 'hex')

// IPLD formats
exports['dag-pb'] = Buffer.from('70', 'hex')
exports['dag-cbor'] = Buffer.from('71', 'hex')
exports['git-raw'] = Buffer.from('78', 'hex')
exports['eth-block'] = Buffer.from('90', 'hex')
exports['eth-block-list'] = Buffer.from('91', 'hex')
exports['eth-tx-trie'] = Buffer.from('92', 'hex')
exports['eth-tx'] = Buffer.from('93', 'hex')
exports['eth-tx-receipt-trie'] = Buffer.from('94', 'hex')
exports['eth-tx-receipt'] = Buffer.from('95', 'hex')
exports['eth-state-trie'] = Buffer.from('96', 'hex')
exports['eth-account-snapshot'] = Buffer.from('97', 'hex')
exports['eth-storage-trie'] = Buffer.from('98', 'hex')

exports['bitcoin-block'] = Buffer.from('b0', 'hex')
exports['bitcoin-tx'] = Buffer.from('b1', 'hex')
exports['zcash-block'] = Buffer.from('c0', 'hex')
exports['zcash-tx'] = Buffer.from('c1', 'hex')
exports['stellar-block'] = Buffer.from('d0', 'hex')
exports['stellar-tx'] = Buffer.from('d1', 'hex')

exports['torrent-info'] = Buffer.from('7b', 'hex')
exports['torrent-file'] = Buffer.from('7c', 'hex')
exports['ed25519-pub'] = Buffer.from('ed', 'hex')
